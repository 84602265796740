import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { layout: 'app' },
    component: Home
  },
  {
    path: '/perfil',
    name: 'perfil',
    meta: {
      layout: 'app',
      title: 'Editar Cuenta'
    },
    component: () => import(/* webpackChunkName: "perfil" */ '../views/Perfil.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    meta: { layout: 'simple' },
    component: () => import(/* webpackChunkName: "forgot" */ '../views/Forgot.vue')
  },
  {
    path: '/password/reset/:token',
    name: 'password_reset',
    meta: { layout: 'simple' },
    component: () => import('../views/PasswordReset.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'simple' },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/turnos',
    name: 'turnos',
    meta: {
      layout: 'app',
      title: 'Turnos'
    },
    component: () => import('../views/Turnos.vue')
  },
  {
    path: '/dias-no-laborables',
    name: 'dias_no_laborables',
    meta: { layout: 'app' },
    component: () => import('../views/DiasNoLaborables.vue')
  },
  {
    path: '/medicos',
    name: 'medicos',
    meta: { layout: 'app' },
    component: () => import('../views/Medicos.vue')
  },
  {
    path: '/consultorios',
    name: 'consultorios',
    meta: { layout: 'app' },
    component: () => import('../views/Consultorios.vue')
  },
  {
    path: '/obras-sociales',
    name: 'obras_sociales',
    meta: { layout: 'app' },
    component: () => import('../views/ObrasSociales.vue')
  },
  {
    path: '/pacientes',
    name: 'pacientes',
    meta: { layout: 'app' },
    component: () => import('../views/Pacientes.vue')
  },
  {
    path: '/historias-clinicas',
    name: 'historias-clinicas',
    meta: {
      layout: 'app',
      title: 'Historias Clínicas'
    },
    component: () => import('../views/HistoriasClinicas.vue')
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    meta: { layout: 'app' },
    component: () => import('../views/Usuarios.vue')
  },
  {
    path: '/usuarios/sin-aprobacion',
    name: 'usuarios-sin-aprobacion',
    meta: { layout: 'app' },
    component: () => import('../views/UsuariosSinAprobacion.vue')
  },
  {
    path: '/ajustes',
    name: 'ajustes',
    meta: { layout: 'app' },
    component: () => import('../views/Ajustes.vue')
  },
  {
    path: '/motivos',
    name: 'motivos',
    meta: { layout: 'app' },
    component: () => import('../views/Motivos.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/forgot']
  const isPasswordResetRoute = to.path.startsWith('/password/reset/')
  const authRequired = !publicPages.includes(to.path) && !isPasswordResetRoute
  const loggedIn = sessionStorage.getItem('token') // Es válido el token?

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next()
})

export default router
