<template>
  <v-app>
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      fixed
      app
    >
      <v-list
        dense
        nav
      >
        <v-list-item
          link
          :to="{name: 'turnos'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-calendar-month-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Turnos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'dias_no_laborables'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-calendar-remove-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Días No Laborables</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'pacientes'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-account-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Pacientes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'historias-clinicas'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text-clock-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Historias Clínicas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'obras_sociales'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Obras Sociales</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'consultorios'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-office-building-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Consultorios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'medicos'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-briefcase-account-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Médicos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'motivos'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-comment-multiple-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Motivos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          exact
          :to="{name: 'usuarios'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Usuarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          exact
          :to="{name: 'usuarios-sin-aprobacion'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-question-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Usuarios Sin Aprobar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          exact
          :to="{name: 'ajustes'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ajustes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      color="indigo"
      dense
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">
        JQTurnos Admin
        <span v-if="this.$route.meta.title"> &mdash; {{ this.$route.meta.title }}</span>
      </v-toolbar-title>

      <v-toolbar-title v-else>
        {{ this.$route.meta.title ? this.$route.meta.title : 'JQTurnos Admin' }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon to="/perfil">
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-btn>

      <v-btn icon @click="logout()">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app class="caption">
      JQTurnos Admin &copy; {{ new Date().getFullYear() }} - Desarrollado por&nbsp;<a href="https://jaque.com.ar" target="_blank" rel="nofollow" class="font-weight-bold text-decoration-none">JAQUE Software</a>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      drawer: null
    }),
    computed: {
      isAdmin () {
        return this.$store.state.admin
      },
    },
    methods: {
      logout: function () {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('admin')
        this.$store.state.loggedIn = false
        this.$store.state.admin = false
        this.$router.push({name: 'login'}, () => {})
      },
    },
  }
</script>
